// all routes pathname can be imported in this file
module.exports = {
  login: "/auth/login",
  main: "/",
  users: "/users",
  providers: "/providers",
  workshops: "/workshops",
  carModels: "/carModels",
  wallet: {
    withdrawalRequests: "/wallet/withdrawalRequests",
    fulfilled: "/wallet/fulfilled",
    negativeAmounts: "/wallet/negativeAmounts",
  },
  requests: {
    incoming: "/requests/incoming",
    invoice: "/requests/invoice",
  },
};
