import React from 'react'
import {Field} from 'formik'
import {CountryDropdown} from 'react-country-region-selector'

function FormikCountry({
  label,
  name,
  className,
  onChangeCallback,
  onBlurCallback,
  ...props
}) {
  return (
    <>
      <label htmlFor={name} className="font-normal text-base text-labelColor">
        {label}
      </label>
      <Field name={name}>
        {({form, field}) => {
          const {errors, touched, setFieldValue, setFieldTouched} = form
          return (
            <CountryDropdown
              id={name}
              {...field}
              {...props}
              className={`block mt-1 ${className} ${
                errors[name] ? 'bg-red-100' : ''
              }`}
              onChange={val => {
                setFieldValue(name, val)
              }}
            />
          )
        }}
      </Field>
    </>
  )
}
export default FormikCountry
