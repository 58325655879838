import moment from 'moment'
import 'moment/locale/ar'
import 'moment/locale/en-ca'

export function formatTime(time) {
  if (time === '') {
    return ''
  }
  return moment(time).format('hh:mm A')
}

export function formatDate(date) {
  if (!date) {
    return ''
  }
  return moment(date).format('DD-MM-YYYY')
}

export function stringToDate(date) {
  return moment(date, 'DD-MM-YYYY').utc().format()
}

export function timeToDate(date, time) {
  if (date !== '' && time === '') {
    return date
  } else if (time !== '' && date === '') {
    return time
  }
  return moment(`${date} ${time}`, 'DD-MM-YYYY hh:mm A').local().format()
}

export function fromatDateTime(datetime) {
  return moment(datetime).local().format('DD-MM-YYYY hh:mm A')
}

export function nOfDaysBetween(date1, date2) {
  const a = moment(date1).local().startOf('day')
  const b = moment(date2).local().startOf('day')

  return Math.abs(a.local().diff(b.local(), 'day'))
}
