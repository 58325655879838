const CustomLoader = ({isLoading}) => {
  return (
    isLoading && (
      <div className="h-screen w-screen flex items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-3">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    )
  )
}

export default CustomLoader
