import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import "./App.css";
import AuthLayout from "./layout/auth-layout";
import DefaultLayout from "./layout/default-layout";
import routes from "./routes";

function App() {
  return (
    <div className="main-font">
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Route path="/" component={DefaultLayout} />
        <Redirect to={routes.login} />
      </Switch>
    </div>
  );
}

export default App;
