import jwt_decode from "jwt-decode";
import { keys } from "./keys";

class Auth {
  static isAuth() {
    let token = localStorage.getItem("token");

    let expired = false;
    if (![null, "null", undefined, "undefined", ""].includes(token)) {
      expired = !this.isExpired(token);
    }
    return expired;
  }

  static isSignupAuth() {
    let signupToken = localStorage.getItem("signupToken");
    let expired = false;
    if (![null, "null", undefined, "undefined", ""].includes(signupToken)) {
      expired = !this.isExpired(signupToken);
    }

    return expired;
  }

  static getToken() {
    let token = localStorage.getItem("token");

    if ([null, "null", undefined, "undefined", ""].includes(token)) return null;

    return token;
  }

  static getSignupToken() {
    let signupToken = localStorage.getItem("signupToken");

    if ([null, "null", undefined, "undefined", ""].includes(signupToken))
      return null;
    return signupToken;
  }

  static setToken(val) {
    localStorage.setItem("token", val);
    return val;
  }

  static async setSignupToken(val) {
    await localStorage.setItem("signupToken", val);
    return val;
  }

  static getUserId() {
    const userId = JSON.parse(localStorage.getItem("user") || "[]");
    return userId?._id;
  }

  static getUserData() {
    const user = JSON.parse(localStorage.getItem("user") || "[]");

    return user;
  }

  static getShopId() {
    const shopId = JSON.parse(localStorage.getItem("shop") || "[]");
    return shopId;
  }

  static isExpired(myToken) {
    const decodeToken = jwt_decode(myToken);
    const now = new Date().getTime() / 1000;
    if (now > decodeToken.exp) {
      return true;
    } else {
      return false;
    }
  }

  static isTechnician() {
    const user = JSON.parse(localStorage.getItem("user") || "[]");

    if (![keys.ROLES.tech].includes(user?.role)) {
      return false;
    }

    return true;
  }

  static isAdmin() {
    const user = JSON.parse(localStorage.getItem("user") || "[]");

    if (![keys.ROLES.admin].includes(user?.role)) {
      return false;
    }

    return true;
  }

  static isShowroom() {
    const user = JSON.parse(localStorage.getItem("user") || "[]");

    if (![keys.ROLES.showroom].includes(user?.role)) {
      return false;
    }

    return true;
  }

  static isServiceProvider() {
    const user = JSON.parse(localStorage.getItem("user") || "[]");

    if (![keys.ROLES.service].includes(user?.role)) {
      return false;
    }

    return true;
  }

  static isSparePart() {
    const user = JSON.parse(localStorage.getItem("user") || "[]");

    if (![keys.ROLES.sparePart].includes(user?.role)) {
      return false;
    }

    return true;
  }

  static isCarRental() {
    const user = JSON.parse(localStorage.getItem("user") || "[]");

    if (![keys.ROLES.carRental].includes(user?.role)) {
      return false;
    }

    return true;
  }

  static isCarRecovery() {
    const user = JSON.parse(localStorage.getItem("user") || "[]");

    if (![keys.ROLES.carRecovery].includes(user?.role)) {
      return false;
    }

    return true;
  }

  static isInsurance() {
    const user = JSON.parse(localStorage.getItem("user") || "[]");

    if (![keys.ROLES.insurance].includes(user?.role)) {
      return false;
    }

    return true;
  }

  static logout() {
    localStorage.setItem("token", "");
    localStorage.setItem("signupToken", "");
    localStorage.setItem("shop", 0);
    localStorage.setItem("user", null);
  }

  static _decodeToken(token) {
    try {
      const decoded = jwt_decode(token);
      return decoded;
    } catch (e) {
      return false;
    }
  }
}

export default Auth;
