import { createContext, useContext, useLayoutEffect, useState } from "react";
import useAxios from "../hooks/useAxios";

const StateContext = createContext();
StateContext.displayName = "StateContext"; // react dev tools doesn't display the name of the context

function StateProvider({ children }) {
  const [currentModal, setCurrentModal] = useState([]);
  const [OTPConfirmResult, setOTPConfirmResult] = useState(null);
  const [registrationSteps, setRegistrationSteps] = useState(1);
  const [update, setUpdate] = useState(false);
  const [activeModal, setActiveModal] = useState({});
  const { run } = useAxios();

  useLayoutEffect(() => {
    resetAll();
  }, []);

  //   Add new modal
  const addNewModal = (modalName, data) => {
    currentModal.push({ name: modalName, data, isOpened: true });
    setCurrentModal((prev) => prev);
    setActiveModal({ name: modalName, data: data, isOpened: true });
  };

  //   Get Current Modal
  const getCurrentModal = () =>
    setActiveModal(currentModal[currentModal?.length - 1]);

  // Check If Modal Exist
  const isModalExist = (modalName) => {
    const index = currentModal.findIndex((o) => [modalName].includes(o?.name));

    if (index === -1) return false;

    return true;
  };

  //   Remove Modal
  const removeModal = () => {
    currentModal.splice(currentModal.length - 1, 1);

    setCurrentModal((prev) => prev);
    setActiveModal(currentModal?.[currentModal?.length - 1] || []);
    return;
  };

  // Get data from any modal by modal name
  const getDataFromPreviousModal = () => {
    // const index = currentModal.findIndex(o => [modalName].includes(o?.name))

    // if (index === -1) return false

    return currentModal[currentModal.length - 2]?.data;
  };

  //   Reset all states
  const resetAll = () => {
    setCurrentModal([]);
    setActiveModal({});
    setOTPConfirmResult(null);
    setUpdate(false);
    setRegistrationSteps(1);
  };

  return (
    <StateContext.Provider
      value={{
        addNewModal,
        removeModal,
        resetAll,
        isModalExist,
        getCurrentModal,
        activeModal,
        registrationSteps,
        setRegistrationSteps,
        getDataFromPreviousModal,
        update,
        setUpdate,
        OTPConfirmResult,
        setOTPConfirmResult,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

function useStateContext() {
  const {
    addNewModal,
    removeModal,
    getCurrentModal,
    activeModal,
    registrationSteps,
    isModalExist,
    setRegistrationSteps,
    update,
    setUpdate,
    getDataFromPreviousModal,
    resetAll,
    OTPConfirmResult,
    setOTPConfirmResult,
  } = useContext(StateContext);
  // if (!context) {
  //   throw new Error('useStateContext must be used within a StateProvider')
  // }

  return {
    addNewModal,
    removeModal,
    getCurrentModal,
    activeModal,
    isModalExist,
    registrationSteps,
    setRegistrationSteps,
    getDataFromPreviousModal,
    resetAll,
    update,
    setUpdate,
    OTPConfirmResult,
    setOTPConfirmResult,
  };
}

export { StateProvider, useStateContext };
