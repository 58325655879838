import { useLocation } from "react-router-dom";
import Auth from "../../../config/auth";
import { authAxios } from "../../../config/axios-config";
import { api_routes } from "../../../config/keys";
import routes from "../../../routes";
import useAxios from "../../hooks/useAxios";
import { useStateContext } from "../state-context";

const { createContext, useContext, useState, useEffect } = require("react");

const CarModelApiContext = createContext();
CarModelApiContext.displayName = "CarModelApiContext"; // react dev tools doesn't display the name of the context

const initialState = {
  activeTab: "others",
  brands: [],
  brand: "",
  carModel: "",
  carModels: [],
  page: 1,
  begin: 1,
};

function CarModelApiProvider({ children }) {
  const [allBrands, setAllBrands] = useState([]);
  const { removeModal, addNewModal, activeModal } = useStateContext();
  const [allCarModels, setAllCarModels] = useState({});
  const { run, isLoading } = useAxios();
  const [carModelRequests, setCarModelRequests] = useState({});
  const [state, setState] = useState(initialState);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!Auth.isAuth() || !Auth.isAdmin()) return;

    getAllBrands();
    state.activeTab == "others" ? getCarModelRequests() : getAllCarModels();

    return () => {
      if (![routes.carModels].includes(pathname)) setState(initialState);
    };
  }, [state.brand, state.carModel, state.page, state.activeTab, pathname]);

  // Get all brands
  const getAllBrands = async () => {
    const result = await run(authAxios.get(api_routes.carModels.all));

    setAllBrands(result.data.data);
    setAllBrandsOnDropdown(result.data?.data);
  };

  // Get all car models
  const getAllCarModels = async () => {
    const result = await run(
      authAxios.get(
        api_routes.carModels.all +
          "?type=paginate&page=" +
          state.page +
          "&brand=" +
          state.brand +
          "&carModel=" +
          state.carModel
      )
    );

    setAllCarModels(result.data?.data);
  };

  // Select carModels from brands
  const selectCarModelsFromBrands = (brand) => {
    if (brand === "")
      return setState((prev) => ({
        ...prev,
        brand: "",
        carModel: "",
        carModels: [],
        page: 1,
        begin: 1,
      }));

    let carModels = allBrands.filter((o) => [brand].includes(o.brand));

    carModels = carModels?.[0]?.models?.map((m, i) => ({
      key: i,
      text: m,
      value: m,
    })) || [{ key: 0, text: "No Models", value: 0, disabled: true }];

    setState((prev) => ({ ...prev, brand, carModels }));
  };

  // Get other car model requests
  const getCarModelRequests = async () => {
    const result = await run(
      authAxios.get(
        api_routes.carModels.carModelRequests(state.page) +
          "?brand=" +
          state.brand
      )
    );

    setCarModelRequests(result.data?.data);
  };

  // Set All Brands to select in dropdown
  const setAllBrandsOnDropdown = (brandsContent) => {
    const brandsOptions = brandsContent.map((b, i) => ({
      key: i,
      text: b?.brand,
      value: b?.brand,
    }));

    setState((prev) => ({ ...prev, brands: brandsOptions }));
  };

  // Confirm car model request
  const confirmCarModelRequest = async () => {
    const result = await run(
      authAxios.post(api_routes.carModels.addCarModelToList, {
        carModelData: [activeModal?.data],
        type: "add",
      })
    );

    removeModal();
    if (result?.data?.data?.docs?.length > 0)
      addNewModal("carModelReplacement", {
        ...result?.data?.data,
        _id: activeModal?.data?._id,
      });
  };

  //   Delete car model request
  const deleteCarModelRequest = async () => {
    await run(
      authAxios.delete(
        api_routes.carModels.deleteCarModelRequest(activeModal?.data?._id)
      )
    );

    removeModal();
  };

  return (
    <CarModelApiContext.Provider
      value={{
        allBrands,
        isLoading,
        selectCarModelsFromBrands,
        state,
        setState,
        allCarModels,
        carModelRequests,
        confirmCarModelRequest,
        deleteCarModelRequest,
      }}
    >
      {children}
    </CarModelApiContext.Provider>
  );
}

function useCarModelApi() {
  const {
    allBrands,
    isLoading,
    selectCarModelsFromBrands,
    state,
    setState,
    allCarModels,
    carModelRequests,
    confirmCarModelRequest,
    deleteCarModelRequest,
  } = useContext(CarModelApiContext);
  // if (!context) {
  //   throw new Error('useStateContext must be used within a StateProvider')
  // }

  return {
    allBrands,
    isLoading,
    selectCarModelsFromBrands,
    state,
    setState,
    allCarModels,
    carModelRequests,
    confirmCarModelRequest,
    deleteCarModelRequest,
  };
}

export { CarModelApiProvider, useCarModelApi };
