import React from "react";
import { Field } from "formik";
import IntlTelInput from "react-intl-tel-input/dist/components/IntlTelInput";
import "react-intl-tel-input/dist/main.css";
import { get } from "wild-wild-path";

function FormikPhone({ label, name, className, containerClassName, ...props }) {
  return (
    <>
      <label htmlFor={name} className="font-normal text-base text-labelColor">
        {label}
      </label>
      <Field name={name}>
        {({ form, field }) => {
          const { errors, touched, setFieldValue } = form;
          return (
            <IntlTelInput
              containerClassName={`intl-tel-input w-100 main-font block  mt-1 ${containerClassName} ${
                errors[name] && "bg-red-100"
              }`}
              dir={"ltr"}
              nationalMode={false}
              autoFocus={props?.autoFocus || false}
              inputClassName={`pl-14 main-font main-direction ${className} ${
                errors[name] && "text-red-100"
              }`}
              id={name}
              {...field}
              {...props}
              error={get(touched, name) && get(errors, name)}
              onPhoneNumberChange={(isValid, phone, country) => {
                setFieldValue(name, phone.replace(" ", ""));
              }}
            />
          );
        }}
      </Field>
    </>
  );
}

export default FormikPhone;
