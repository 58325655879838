import { Field } from "formik";
import moment from "moment";
import { DateInput } from "semantic-ui-calendar-react";
import { Icon } from "semantic-ui-react";
import { formatDate, stringToDate } from "../date-format";

function FormikDate({ label, name, ...props }) {
  return (
    <>
      <label htmlFor={name} className="font-normal text-labelColor text-base">
        {label}
      </label>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldTouched, setFieldValue, errors, touched } = form;
          const { value } = field;
          return (
            <DateInput
              id={name}
              closable
              {...field}
              {...props}
              value={formatDate(value) || ""}
              iconPosition="left"
              hideMobileKeyboard={true}
              minDate={moment().utc().format("DD-MM-YYYY")}
              startMode="month"
              clearable
              clearIcon={<Icon name="remove" color="red" />}
              autoComplete={"off"}
              className="mt-2"
              onClear={() => setFieldValue(name, "")}
              onBlur={() => setFieldTouched(name, true)}
              onChange={(e, { value }) =>
                setFieldValue(name, stringToDate(value))
              }
              error={touched[name] && errors[name]}
            />
          );
        }}
      </Field>
    </>
  );
}

export default FormikDate;
