import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Auth from "../../../config/auth";
import { authAxios } from "../../../config/axios-config";
import { api_routes } from "../../../config/keys";
import routes from "../../../routes";
import useAxios from "../../hooks/useAxios";

const ProviderApiContext = createContext();
ProviderApiContext.displayName = "ProviderApiContext"; // react dev tools doesn't display the name of the context

function ProviderApiProvider({ children }) {
  const [providers, setProviders] = useState({});
  const { run, isLoading, isError } = useAxios();
  const [state, setState] = useState({ page: 1, begin: 1, status: null });
  const { pathname } = useLocation();

  useEffect(() => {
    if (!Auth.isAuth() || !Auth.isAdmin()) return;

    getAllProviders();

    return () => {
      setProviders({});
    };
  }, [state.page, state.status, pathname]);

  // Get all providers
  const getAllProviders = async () => {
    const result = await run(
      authAxios.get(
        api_routes.providers[
          pathname == routes.providers ? "all" : "allWorkShops"
        ](state.page, state.status)
      )
    );

    setProviders(result?.data?.data);
  };

  //   Suspend provider
  const suspendWorkShop = async (id) => {
    await run(
      authAxios.patch(api_routes.providers.suspendShop, { shopId: id })
    );

    // console.log(result);
    return isError;
  };

  // Provider approval
  const providerApproval = async (id, approved) => {
    const result = await run(
      authAxios.post(api_routes.providers.approveProvider, {
        userId: id,
        isApproved: approved,
      })
    );

    return result.data;
  };

  // Check shop details completion
  const isShopCompleted = async (id) => {
    const result = await run(
      authAxios.get(api_routes.providers.isWorkshopCompleted(id))
    );

    return result.data?.data;
  };

  return (
    <ProviderApiContext.Provider
      value={{
        isLoading,
        isError,
        setState,
        state,
        providers,
        getAllProviders,
        suspendWorkShop,
        providerApproval,
        isShopCompleted,
      }}
    >
      {children}
    </ProviderApiContext.Provider>
  );
}

function useProviderApi() {
  const {
    isLoading,
    isError,
    state,
    setState,
    providers,
    getAllProviders,
    suspendWorkShop,
    providerApproval,
    isShopCompleted,
  } = useContext(ProviderApiContext);
  // if (!context) {
  //   throw new Error('useStateContext must be used within a StateProvider')
  // }

  return {
    isLoading,
    isError,
    state,
    setState,
    providers,
    getAllProviders,
    suspendWorkShop,
    providerApproval,
    isShopCompleted,
  };
}

export { ProviderApiProvider, useProviderApi };
