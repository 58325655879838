import React from "react";
import { useToasts } from "react-toast-notifications";
import Auth from "../../config/auth";
import routes from "../../routes";

function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false);
  React.useLayoutEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);
  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : void 0),
    [dispatch]
  );
}

const defaultInitialState = { status: "idle", data: null, error: null };

function useAxios(initialState) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  });
  const [{ status, data, error }, setState] = React.useReducer(
    (s, a) => ({ ...s, ...a }),
    initialStateRef.current
  );
  const { addToast, removeAllToasts } = useToasts();

  const safeSetState = useSafeDispatch(setState);

  const setData = React.useCallback(
    (data) => safeSetState({ data, status: "resolved" }),
    [safeSetState]
  );
  const setError = React.useCallback(
    (error) => safeSetState({ error, status: "rejected" }),
    [safeSetState]
  );
  const reset = React.useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState]
  );

  const run = React.useCallback(
    (promise, config = {}) => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAxios().run must be a promise. Maybe a function that's passed isn't returning anything?`
        );
      }
      safeSetState({ status: "pending" });
      return promise.then(
        (response) => {
          setData(response?.data?.data);
          if (response?.data?.message) {
            removeAllToasts();
            addToast(response?.data?.message, { appearance: "success" });
          }
          return response;
        },
        (error) => {
          removeAllToasts();

          const errorMessage = error?.response?.data;
          if (config.throwOnError) throw new Error(errorMessage);
          // console.log(error?.message);
          // if (error?.message)
          //   return addToast(error?.message, { appearance: "error" });
          // console.log(errorMessage, error);
          errorMessage?.errors?.map((err) => {
            // console.log(err);
            if (
              [
                "authToken",
                "permission",
                "notAuthorized",
                "token",
                "deviceId",
                "authToken/notExist",
              ].includes(err?.field)
            ) {
              Auth.logout();
              return (window.location.pathname = routes.login);
            }

            addToast(err?.message, { appearance: "error" });
          });
          setError(errorMessage);
          return Promise.reject(errorMessage);
        }
      );
    },
    [safeSetState, setData, setError]
  );

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === "idle",
    isLoading: status === "pending",
    isError: status === "rejected",
    isSuccess: status === "resolved",

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };
}

export default useAxios;
