import { Formik } from "formik";
import { Button, Form, Image } from "semantic-ui-react";
import logoImg from "../../assets/images/bookarage_logo.svg";
import FormikControl from "../../components/common/formik/FormikControl";
import { useAuthState } from "../../components/context/auth-context";

const LoginPage = () => {
  const { login, isLoading } = useAuthState();

  const handleOnSubmit = async (values) => {
    login(values.emailOrPhoneNumber, values.password);
  };

  return (
    <div className="py-5 lg:px-20">
      <div className="mt-10">
        <Image src={logoImg} className="w-40 mx-auto" />
      </div>

      <div className="mt-12 mb-20 mx-20 scale-in">
        <Formik
          initialValues={{ emailOrPhoneNumber: "", password: "" }}
          onSubmit={handleOnSubmit}
        >
          {(formik) => (
            <Form onSubmit={formik.submitForm}>
              <div></div>
              <Form.Field required>
                <FormikControl
                  name="emailOrPhoneNumber"
                  control={"input"}
                  disabled={isLoading}
                  loading={isLoading}
                  className="auth-input"
                  label="Email address or phone number"
                />
              </Form.Field>
              <Form.Field required>
                <FormikControl
                  name="password"
                  control={"input"}
                  disabled={isLoading}
                  loading={isLoading}
                  className="auth-input"
                  type="password"
                  label="Password"
                />
              </Form.Field>

              <Form.Field className="mt-10">
                <Button
                  content="Login"
                  type="submit"
                  disabled={isLoading}
                  loading={isLoading}
                  className="w-full bg-primaryRedColor-default text-lg font-medium text-white rounded-2xl main-font"
                />
              </Form.Field>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginPage;
