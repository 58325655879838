import {Field} from 'formik'
import moment from 'moment'
import {TimeInput} from 'semantic-ui-calendar-react'
import {Icon} from 'semantic-ui-react'

function FormikTime({label, name, ...props}) {
  return (
    <>
      <label htmlFor={name} className="font-normal text-labelColor text-base">
        {label}
      </label>
      <Field name={name}>
        {({form, field}) => {
          const {setFieldValue, setFieldTouched, errors, touched} = form
          const {value} = field

          return (
            <TimeInput
              id={name}
              closable
              iconPosition="left"
              timeFormat="AMPM"
              hideMobileKeyboard={true}
              disableMinute={true}
              clearable
              clearIcon={<Icon name="remove" color="red" />}
              autoComplete={'off'}
              className="mt-2"
              onClear={() => setFieldValue(name, '')}
              {...field}
              {...props}
              // value={value}
              onBlur={() => setFieldTouched(name, true)}
              onChange={(e, {value}) => {
                return setFieldValue(name, moment(value, 'hh:mm A').format())
              }}
              error={touched[name] && errors[name]}
            />
          )
        }}
      </Field>
    </>
  )
}

export default FormikTime
