import { useLocation } from "react-router-dom";
import Auth from "../../../config/auth";
import { authAxios } from "../../../config/axios-config";
import { api_routes } from "../../../config/keys";
import routes from "../../../routes";
import { paginator } from "../../../utils/handle-docs";
import useAxios from "../../hooks/useAxios";

const { createContext, useContext, useState, useEffect } = require("react");

const UserApiContext = createContext();
UserApiContext.displayName = "UserApiContext"; // react dev tools doesn't display the name of the context

function UserApiProvider({ children }) {
  const [update, setUpdate] = useState(false);
  const [users, setUsers] = useState({});
  const { run, isLoading } = useAxios();
  const [state, setState] = useState({ page: 1, begin: 1, status: null });
  const [carHealthState, setCarHealthState] = useState({
    carHealth: {},
    page: 1,
    begin: 1,
  });
  const { pathname } = useLocation();

  useEffect(() => {
    if (!Auth.isAuth() || !Auth.isAdmin()) return;

    getRegisteredUsers();
    return () => {
      if (pathname !== routes.users)
        setState({ page: 1, begin: 1, status: null });

      setUsers({});
    };
  }, [state.page, state.status, update, pathname]);

  // Get registered users
  const getRegisteredUsers = async () => {
    if (!Auth.isAuth() || !Auth.isAdmin()) return;

    const result = await run(
      authAxios.get(api_routes.users.registeredUsers(state.page, state.status))
    );

    setUsers(result?.data?.data);
    return result?.data?.data;
  };

  // Suspend user
  const suspendUser = async (id) => {
    const result = await run(authAxios.patch(api_routes.users.suspendUser(id)));

    console.log(result);
    setUpdate((prev) => !prev);
  };

  // Get car history
  const getCarHistory = async (carId, page) => {
    const result = await run(authAxios.get(api_routes.users.carHistory(carId)));

    console.log(result);
    const docs = paginator(result?.data?.data?.[0]?.serviceRequests, page, 10);

    return docs;
  };

  return (
    <UserApiContext.Provider
      value={{
        isLoading,
        // update,
        // setUpdate,
        getRegisteredUsers,
        users,
        suspendUser,
        setState,
        state,
        carHealthState,
        setCarHealthState,
        getCarHistory,
      }}
    >
      {children}
    </UserApiContext.Provider>
  );
}

function useUserApi() {
  const {
    isLoading,
    users,
    suspendUser,
    getRegisteredUsers,
    state,
    setState,
    carHealthState,
    setCarHealthState,
    getCarHistory,
  } = useContext(UserApiContext);
  // if (!context) {
  //   throw new Error('useStateContext must be used within a StateProvider')
  // }

  return {
    isLoading,
    users,
    suspendUser,
    state,
    setState,
    getRegisteredUsers,
    carHealthState,
    setCarHealthState,
    getCarHistory,
  };
}

export { UserApiProvider, useUserApi };
