import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ToastContainer from "./components/shared/ToastContainer";
import { ToastProvider } from "react-toast-notifications";
import { StateProvider } from "./components/context/state-context";
import { UserProvider } from "./components/context/user-context";
import { AuthProvider } from "./components/context/auth-context";
import { BrowserRouter } from "react-router-dom";
import { UserApiProvider } from "./components/context/Apis/user-api-context";
import { ProviderApiProvider } from "./components/context/Apis/provider-api-context";
import { CarModelApiProvider } from "./components/context/Apis/carModels-api-context";
import { RequestApiProvider } from "./components/context/Apis/requests-api-context";

import "semantic-ui-css/semantic.min.css";
import "./assets/styles/fonts.css";
import "./assets/styles/tailwind.css";
import "./assets/styles/app.css";
import "./assets/styles/rating.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ToastProvider
      components={ToastContainer}
      autoDismiss={true}
      placement="top-center"
      autoDismissTimeout={2000}
    >
      <UserProvider>
        <AuthProvider>
          <StateProvider>
            <UserApiProvider>
              <ProviderApiProvider>
                <CarModelApiProvider>
                  <RequestApiProvider>
                    <App />
                  </RequestApiProvider>
                </CarModelApiProvider>
              </ProviderApiProvider>
            </UserApiProvider>
          </StateProvider>
        </AuthProvider>
      </UserProvider>
    </ToastProvider>
  </BrowserRouter>
);
