import React from "react";
import { Dropdown, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

import bookarage_logo from "../../assets/images/bookarage_logo.svg";
import routes from "../../routes";
import { useAuthState } from "../context/auth-context";

const Navbar = ({ isSmall, withLogo }) => {
  const { logout } = useAuthState();

  return (
    <nav
      className={`${
        isSmall || withLogo ? "justify-between" : "justify-between "
      } fixed z-50 w-full bg-white lg:px-10 pl-16 pr-10 py-2 flex items-center shadow h-20 `}
    >
      <div className="flex items-center">
        <Image src={bookarage_logo} className=" h-12" />
      </div>

      <div className="">
        <Dropdown
          direction="left"
          trigger={
            <div className="flex flex-row items-center bg-white">
              <div className="flex flex-col justify-center items-center">
                <p className="text-base font-normal">Bookarage admin</p>
              </div>
            </div>
          }
          className="flex items-center adminNavDropdown"
        >
          <Dropdown.Menu className="mt-2 w-40 bg-gray-50 overflow-hidden">
            <Link to={routes.users}>
              <Dropdown.Item className="text-black hover:text-black text-sm hover:bg-gray-100 transition-colors duration-300 text-center py-2">
                {"Profile"}
              </Dropdown.Item>
            </Link>
            <Dropdown.Item
              className="text-black hover:text-black text-sm hover:bg-gray-100 transition-colors duration-300 text-center py-2"
              onClick={logout}
            >
              {"Logout"}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  );
};

export default Navbar;
