import { Route } from "react-router";
import Login from "../pages/auth/login";
import routes from "../routes";
import authBg from "../assets/images/auth-bg.png";

const AuthLayout = () => {
  return (
    <div
      className=" flex justify-center items-center w-full bg-no-repeat h-screen bg-cover"
      style={{
        background: `url(${authBg})`,
      }}
    >
      <div className="absolute top-0 left-0 w-full bg-black bg-opacity-50 h-screen"></div>
      <div className="flex relative items-center justify-center h-full w-full">
        <div className="max-w-2xl mt-3 w-full max-h-screen rounded-[17px] bg-white bg-opacity-80">
          <Route exact path={routes.login} component={Login} />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
