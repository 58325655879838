import Icon from "awesome-react-icons";
import React, { useEffect, useState } from "react";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useHistory, useLocation } from "react-router-dom";
import { Image } from "semantic-ui-react";
import { has } from "wild-wild-path";
import car_models from "../../assets/images/side-bar-icons/car_models.svg";
import dashboard_icon from "../../assets/images/side-bar-icons/dashboard_icon.svg";
import increase_icon from "../../assets/images/side-bar-icons/increase_icon.svg";
import invoice_icon from "../../assets/images/side-bar-icons/invoice_icon.svg";
import provider_icon from "../../assets/images/side-bar-icons/provider_icon.svg";
import request_icon from "../../assets/images/side-bar-icons/request_icon.svg";
import user_icon from "../../assets/images/side-bar-icons/user_icon.svg";
import work_shops_icon from "../../assets/images/side-bar-icons/work_shops_icon.svg";
import routes from "../../routes";

export const SidebarMinimal = ({ isSmall }) => {
  const history = useHistory();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const path =
    location.pathname.split("/")[2] ||
    location.pathname.split("/")[1] ||
    "main";

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [isSmall]);

  return (
    <React.Fragment>
      {/* Sidebar Overlay */}
      <div
        onClick={() => setIsSidebarOpen(false)}
        className={`fixed inset-0 z-20 bg-black opacity-50 lg:hidden transition-opacity ${
          isSidebarOpen ? "block" : "hidden"
        }`}
      />

      {/* Menu Button */}
      <div className="lg:hidden  flex">
        <button
          className="btn-menu"
          onClick={() => {
            setIsSidebarOpen((prev) => !prev);
          }}
          type="button"
        >
          <Icon
            name="burger"
            size="massive"
            className="w-8 h-8 absolute z-50 top-5 "
          />
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 mt-20 z-30 w-72 overflow-y-auto transition-transform duration-300 ease-in-out bg-sideBarBg text-gray-800 border-r-2 lg:static lg:inset-0 h-screen flex flex-col ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0"
        }`}
      >
        {/* Sidebar Content */}
        <div className="mt-10"></div>
        <Navigation
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            history.push(itemId);
            setIsSidebarOpen(false);
          }}
          items={[
            {
              title: "Dashboard",
              itemId: routes.main,
              elemBefore: () => <Image src={dashboard_icon} />,
            },
            {
              title: <p className="ml-1">Users</p>,
              itemId: routes.users,
              elemBefore: () => <Image src={user_icon} />,
            },
            {
              title: "Providers",
              itemId: routes.providers,
              elemBefore: () => <Image src={provider_icon} />,
            },
            {
              title: "Workshops",
              itemId: routes.workshops,
              elemBefore: () => <Image src={work_shops_icon} />,
            },
            {
              title: "Car models",
              itemId: routes.carModels,
              elemBefore: () => <Image src={car_models} />,
            },
            {
              title: "Requests",
              elemBefore: () => <Image src={request_icon} />,
              itemId: has(routes.requests, path)
                ? location.pathname
                : routes.requests.incoming,
              subNav: [
                {
                  title: "incoming",
                  itemId: routes.requests.incoming,
                  elemBefore: () => <Image src={increase_icon} />,
                },
                {
                  title: "invoice",
                  itemId: routes.requests.invoice,
                  elemBefore: () => <Image src={invoice_icon} />,
                },
              ],
            },
          ]}
        />
      </div>
    </React.Fragment>
  );
};
