import React, { useEffect } from "react";
import axios from "axios";
import Auth from "../../config/auth";
import { authAxios } from "../../config/axios-config";
import { api_routes } from "../../config/keys";
import useLocalStorage from "../hooks/use-local-storage";
import useAxios from "../hooks/useAxios";
import CustomLoader from "../shared/loader";
import { useUser } from "./user-context";
import routes from "../../routes";
import { useHistory, useLocation } from "react-router-dom";

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [loading, setLoading] = React.useState(true);
  const { user, setUser } = useUser();
  const { run, isLoading, isError } = useAxios();
  const history = useHistory();
  const { pathname } = useLocation();
  const [token, setToken] = useLocalStorage("token", null);

  useEffect(() => {
    if (!Auth.isAuth() || !Auth.isAdmin()) {
      setLoading(false);
      resetStorage();
      history.push(routes.login);
      return;
    }

    if ([routes.login].includes(pathname)) history.push(routes.main);
    else history.push(pathname);

    setLoading(false);
    return () => {
      setLoading(true);
    };
  }, [token, pathname]);

  // Login with email or phone number
  const login = async (emailOrPhoneNumber, password) => {
    const result = await run(
      axios.post(api_routes.auth.login, {
        emailOrPhoneNumber,
        password,
        deviceId: await getIP(),
      })
    );

    setUser(JSON.stringify(result?.data?.data));
    setToken(result?.data?.data?.token);
    return;
  };

  // Get IP as device ID
  const getIP = async () => {
    const ip = await axios.get("https://ipinfo.io/json");

    return ip.data.ip;
  };

  // Reset storage
  const resetStorage = () => {
    setUser(null);
    setToken(null);
  };

  // Logout
  const logout = async () => {
    await run(authAxios.get(api_routes.auth.logout));
    if (isError) return;

    resetStorage();
    history.push(routes.login);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        loading,
        isLoading,
        resetStorage,
        isError,
      }}
    >
      {loading ? (
        <div className="h-screen w-screen flex items-center justify-center">
          <div className="flex flex-col items-center gap-3">
            <CustomLoader isLoading={loading} />
          </div>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
function useAuthState() {
  const { isError, loading, isLoading, login, logout, resetStorage } =
    React.useContext(AuthContext);

  return {
    isError,
    logout,
    loading,
    isLoading,
    login,
    resetStorage,
  };
}
export { useAuthState, AuthProvider };
