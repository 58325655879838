import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import useMediaQuery from "../components/hooks/use-media-query";
import CustomLoader from "../components/shared/loader";
import Navbar from "../components/shared/navbar";
import { SidebarMinimal } from "../components/shared/sidebar-minimal";
import routes from "../routes";
const UserPage = lazy(() => import("../pages/users"));
const ProviderPage = lazy(() => import("../pages/providers"));
const WorkShopsPage = lazy(() => import("../pages/workshops"));
const CarModelsPage = lazy(() => import("../pages/car-models"));
const IncomingRequestsPage = lazy(() =>
  import("../pages/requests/incoming-requests")
);
const InvoicePage = lazy(() => import("../pages/requests/invoice"));

const WithdrawalRequestsPage = lazy(() =>
  import("../pages/wallet/withdrawal-requests")
);
const FullfilledPage = lazy(() => import("../pages/wallet/fullfilled"));
const NegativeAmountsPage = lazy(() =>
  import("../pages/wallet/negative-amounts")
);

const DefaultLayout = () => {
  const isSmall = useMediaQuery("(max-width: 1023px)");

  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add("bg-layoutBg");
    return () => document.body.classList.remove("bg-layoutBg");
  }, []);

  return (
    <>
      <Navbar
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        isSmall={isSmall}
      />

      <div className="flex">
        <SidebarMinimal
          isSmall={isSmall}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />

        <div className="flex-grow mt-20 bg-gray-50 main-grid-container">
          <Suspense fallback={<CustomLoader />}>
            <Switch>
              <Route exact path={routes.users} component={UserPage} />
              <Route exact path={routes.providers} component={ProviderPage} />
              <Route path={routes.workshops} component={WorkShopsPage} />
              <Route path={routes.carModels} component={CarModelsPage} />
              <Route
                path={routes.requests.incoming}
                component={IncomingRequestsPage}
              />
              <Route path={routes.requests.invoice} component={InvoicePage} />

              {/* 
              <Route
                path={routes.wallet.withdrawalRequests}
                component={WithdrawalRequestsPage}
              />
              <Route
                path={routes.wallet.fulfilled}
                component={FullfilledPage}
              />
              <Route
                path={routes.wallet.negativeAmounts}
                component={NegativeAmountsPage}
              /> */}
            </Switch>
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
