import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { has } from "wild-wild-path";
import Auth from "../../../config/auth";
import { authAxios } from "../../../config/axios-config";
import { api_routes } from "../../../config/keys";
import routes from "../../../routes";
import useAxios from "../../hooks/useAxios";

const { createContext, useContext } = require("react");

const RequestApiContext = createContext();
RequestApiContext.displayName = "RequestApiContext"; // react dev tools doesn't display the name of the context

const initialState = {
  page: 1,
  begin: 1,
  status: null,
  filter: null,
};

function RequestApiProvider({ children }) {
  const { run, isLoading } = useAxios();
  const [requests, setRequests] = useState({});
  const [state, setState] = useState(initialState);
  const { pathname } = useLocation();

  useEffect(() => {
    console.log(state.status);
    if (Auth.isAuth() && !Auth.isAdmin()) return;
    console.log(
      routes.requests.invoice === pathname,
      !has(routes.requests, pathname.split("/")?.[2] || "null")
    );
    if (!has(routes.requests, pathname.split("/")?.[2] || "null"))
      return setRequests({});

    pathname === routes.requests.incoming
      ? getAllRequests()
      : pathname === routes.requests.invoice && getAllInvoiceSheets();

    return () => {
      setRequests({});
    };
  }, [state.page, state.status, state.filter, pathname]);

  // Get all incoming requests
  const getAllRequests = async () => {
    const result = await run(
      authAxios.get(api_routes.requests.incoming(state.page, state.status))
    );

    setRequests(result.data?.data);
  };

  // Get all invoice sheets
  const getAllInvoiceSheets = async () => {
    console.log(state.filter);
    const result = await run(
      authAxios.get(api_routes.requests.invoice(state.page, state.status))
    );

    console.log(result.data);

    setRequests(result.data?.data);
  };

  // Get request by request Id
  const getRequestById = async (id) => {
    const result = await run(
      authAxios.get(api_routes.requests.requestById(id))
    );

    return result.data?.data;
  };

  return (
    <RequestApiContext.Provider
      value={{ requests, isLoading, state, setState, getRequestById }}
    >
      {children}
    </RequestApiContext.Provider>
  );
}

function useRequestApi() {
  const { requests, isLoading, state, setState, getRequestById } =
    useContext(RequestApiContext);
  // if (!context) {
  //   throw new Error('useStateContext must be used within a StateProvider')
  // }

  return { requests, isLoading, state, setState, getRequestById };
}

export { RequestApiProvider, useRequestApi };
